import type { Action, DefaultActionReturn, UUID } from 'core/utils/basic.models';
import type { LicensingStatusResponse, LoadCompaniesSuccessType, LoadCompaniesType, ReloadCompaniesType } from './company.models';

export const CompanyActionsTypes = {
  RELOAD_COMPANIES: '[Company] Reload Companies',
  RELOAD_COMPANIES_SUCCESS: '[Company] Reload Companies Success',
  RELOAD_COMPANIES_FAILURE: '[Company] Reload Companies Failure',
  LOAD_COMPANIES: '[Company] Load Companies',
  LOAD_COMPANIES_SUCCESS: '[Company] Load Companies Success',
  LOAD_COMPANIES_FAILURE: '[Company] Load Companies Failure',
  LOAD_LICENSING_STATUS: '[Company] Loading Licensing Status',
  LOAD_LICENSING_STATUS_BY_WORKSPACE: '[Company] Loading Licensing Status By Workspace',
  LOAD_LICENSING_STATUS_SUCCESS: '[Company] Loading Licensing Status Success',
  LOAD_LICENSING_STATUS_FAILURE: '[Company] Loading Licensing Status Failure',
  LOAD_CURRENT_WORKSPACE_ID: '[Company] Loading Current Workspace ID',
  LOAD_CURRENT_WORKSPACE_ID_SUCCESS: '[Company] Loading Current Workspace ID Success',
  LOAD_CURRENT_WORKSPACE_ID_FAILURE: '[Company] Loading Current Workspace ID Failure',
  SET_CURRENT_WORKSPACE_ID: '[Company] Set Current Workspace ID',
  CLEAN_LICENSING_STATUS: '[Company] Loading clean Licensing Status'
};

export const LoadCompanies: LoadCompaniesType = pageableQuery => {
  return {
    type: CompanyActionsTypes.LOAD_COMPANIES,
    payload: pageableQuery
  };
};

export const ReloadCompanies: ReloadCompaniesType = data => {
  return {
    type: CompanyActionsTypes.RELOAD_COMPANIES,
    payload: data
  };
};

export const LoadCompaniesSuccess: LoadCompaniesSuccessType = companyPage => {
  return {
    type: CompanyActionsTypes.LOAD_COMPANIES_SUCCESS,
    payload: companyPage
  };
};

export const LoadCompaniesFailure = (error: unknown) => {
  return {
    type: CompanyActionsTypes.LOAD_COMPANIES_FAILURE,
    payload: error
  };
};

export const LoadOrgLicensingStatus = (companyId: UUID) => {
  return {
    type: CompanyActionsTypes.LOAD_LICENSING_STATUS,
    payload: companyId
  };
};

export const LoadWorkspaceLicensingStatus = (workspaceId: UUID) => {
  return {
    type: CompanyActionsTypes.LOAD_LICENSING_STATUS_BY_WORKSPACE,
    payload: workspaceId
  };
};

export const LoadOrgLicensingStatusSuccess = (data: LicensingStatusResponse) => {
  return {
    type: CompanyActionsTypes.LOAD_LICENSING_STATUS_SUCCESS,
    payload: data
  };
};

export const LoadOrgLicensingStatusFailure = (error: string): Action<string> => {
  return {
    type: CompanyActionsTypes.LOAD_LICENSING_STATUS_FAILURE,
    payload: error
  };
};

export const LoadCurrentWorkspaceId = (orgId: string): DefaultActionReturn<string> => {
  return {
    type: CompanyActionsTypes.LOAD_CURRENT_WORKSPACE_ID,
    payload: orgId
  };
};

export const LoadCurrentWorkspaceIdSuccess = (workspaceId: string): DefaultActionReturn<string> => {
  return {
    type: CompanyActionsTypes.LOAD_CURRENT_WORKSPACE_ID_SUCCESS,
    payload: workspaceId
  };
};

export const SetCurrentWorkspaceId = (workspaceId: string | null): DefaultActionReturn<string | null> => {
  return {
    type: CompanyActionsTypes.SET_CURRENT_WORKSPACE_ID,
    payload: workspaceId
  };
};

export const LoadCurrentWorkspaceIdFailure = (error: string): DefaultActionReturn<string> => {
  return {
    type: CompanyActionsTypes.LOAD_CURRENT_WORKSPACE_ID_FAILURE,
    payload: error
  };
};

export const CleanLicensingStatus = () => {
  return {
    type: CompanyActionsTypes.CLEAN_LICENSING_STATUS
  };
};
