import type { UseQueryResult } from '@tanstack/react-query';
import { useQueries, useQuery } from '@tanstack/react-query';
import type { UUID } from 'core/utils/basic.models';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import type { AvailableFields, FieldCropInfo, FieldCropInfoQuery, NemadigitalCropCycleInfo, NemaDigitalJob } from './nemadigital.model';
import { NemadigitalQueryType } from './nemadigital.model';
import { getNemaDigitalAvailableFields, getNemaDigitalFieldCropInfo, getNemaDigitalJobs } from './nemadigital.service';

export const useNemaDigitalFieldCropInfo = (
  fieldIds: UUID[],
  queryEnabled: boolean,
  currentJob?: NemaDigitalJob,
  hasCropIdentificationEntitlement?: boolean
): FieldCropInfoQuery[] => {
  const dateNow = new Date();
  const endDate = dateNow.toISOString().split('T')[0];
  const startDate = new Date(dateNow.setFullYear(dateNow.getFullYear() - 5)).toISOString().split('T')[0];

  const queries = useQueries({
    queries: fieldIds?.map(fieldId => ({
      queryKey: [NemadigitalQueryType.GET_NEMADIGITAL_FIELD_CROP_INFO, fieldId],
      queryFn: () => {
        const preExistingCrops = currentJob?.job_params?.find(jobParam => jobParam.aoi_id === fieldId)?.crops;
        if (!preExistingCrops) return getNemaDigitalFieldCropInfo(fieldId, startDate, endDate);
        return {
          field_id: fieldId,
          crop_cycles_identified: preExistingCrops.reduce<Record<string, NemadigitalCropCycleInfo[]>>((acc, crop) => {
            const cropCycle = {
              crop_identified: crop.crop_type,
              start_date: crop.start_date,
              end_date: crop.end_date,
              confidence: 1,
              source: 'USER'
            };
            return {
              ...acc,
              [dayjs(crop.end_date).format('YYYY')]: [cropCycle]
            };
          }, {})
        };
      },
      enabled: !!fieldId && queryEnabled
    }))
  });

  return hasCropIdentificationEntitlement
    ? queries.map((query, index) => ({
        fieldId: fieldIds[index],
        query: query as UseQueryResult<FieldCropInfo>
      }))
    : [];
};

export const useNemaDigitalAvailableFields = (
  disabledAvailableFields: boolean,
  workspaceId?: string | null
): UseQueryResult<AvailableFields[], unknown> => {
  const { propertyId } = useParams();

  return useQuery(
    [NemadigitalQueryType.GET_NEMADIGITAL_AVAILABLE_FIELDS, propertyId, workspaceId],
    () => {
      if (propertyId && workspaceId) return getNemaDigitalAvailableFields(propertyId, workspaceId);
      return [];
    },
    { enabled: !!propertyId && !disabledAvailableFields }
  );
};

export const useNemaDigitalJobsByPropertyId = (disabledJobs: boolean): UseQueryResult<NemaDigitalJob[], Error> => {
  const { propertyId } = useParams();

  return useQuery(
    [NemadigitalQueryType.GET_NEMADIGITAL_JOBS_BY_PROPERTY_ID, propertyId],
    () => {
      if (propertyId) return getNemaDigitalJobs(propertyId);
      return [];
    },
    {
      onError: error => {
        return error;
      },
      enabled: !!propertyId && !disabledJobs
    }
  );
};
